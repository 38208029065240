import {CARPOOL_THEME_COLOR} from "../constants/Constatnt";
import carpool from "../statics/images/carpool.webp";
import {HowItWorks} from "./HowItWorks";
import {PixieCarPoolAppDownload} from "./AndroidAppDownload";
import {CarpoolFooter} from "../footer/CarpoolFooter";
import React from "react";

export function CarPoolHome() {
    return <section>
        <div style={{background: CARPOOL_THEME_COLOR}}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Pixie Carpool
                    </h1>
                    <h2 className={"display-4 fw-bold"}>
                        at Airport & Stations
                    </h2>
                    <p className={"fs-5"}>
                        Pixie mart carpool where you can create a pool for Airport and Stations from different
                        localities or vice versa.
                        The people traveling at same route can join your pool or you can join theirs and share the
                        amount, have a fun and enjoying the journey together.
                    </p>
                </div>

                <PixieCarPoolAppDownload />
            </div>
        </div>

        <HowItWorks/>

        <div className={"bg-white"}>
            <div className={"container"}>
                <img width={"100%"} src={carpool} alt={"carpool"}/>
            </div>
        </div>

        <CarpoolFooter />
    </section>
}
