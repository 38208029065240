import {Route, Routes} from "react-router-dom";
import {InspireHome} from "./home/InspireHome";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import React, {useEffect} from "react";
import {SetEnlightenAIDocumentTitle} from "../../../managers/DocumentTitleManager";
import {
    INSPIRE_DISCLAIMER_URL,
    INSPIRE_POLICIES_BASE_URL,
    INSPIRE_PRIVACY_POLICY_URL
} from "./InspireUrl";
import EnlightenAIPrivacyPolicy from "./policies/EnlightenAIPrivacyPolicy";
import EnlightenAIDisclaimerPolicy from "./policies/EnlightenAIDisclaimerPolicy";

export function InspireUrlComponent() {

    useEffect(() => {
        SetEnlightenAIDocumentTitle("Wisdom, Inspiration, and Motivation","Enlighten AI steps in as a compassionate mentor, offering inspiration, motivation, and wisdom to help you find happiness and clarity, gently guiding you on the right path");
    }, []);

    return <Routes>
        <Route path={HOME_URL} element={<InspireHome />} />

        {/*<Route path={TWISTER_APP_DOWNLOAD_URL} element={<TwisterApplicationDownload />} />*/}

        <Route path={INSPIRE_POLICIES_BASE_URL} >
            <Route path={INSPIRE_PRIVACY_POLICY_URL} element={<EnlightenAIPrivacyPolicy />} />
            <Route path={INSPIRE_DISCLAIMER_URL} element={<EnlightenAIDisclaimerPolicy />} />
        </Route>

        {/*<Route path={INSPIRE_FAQ_URL} >*/}
        {/*    <Route path={INSPIRE_FAQ_URL} element={<TongueTwisterFAQ />} />*/}
        {/*</Route>*/}
    </Routes>
}
