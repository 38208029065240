import Footer from "../../../footer/Footer";
import {Policies} from "../../../footer/FooterInterface";
import {TWISTER_DISCLAIMER_URL, TWISTER_FAQ_URL, TWISTER_HOME, TWISTER_PRIVACY_POLICY_URL} from "../TwisterUrl";

export function TwisterFooter({hidePolicies}:{hidePolicies?: boolean;}) {
    return <section>
        <Footer contactUsName={"Pixie Mart - Tongue Twister"}
                faqUrl={TWISTER_HOME + TWISTER_FAQ_URL}
                policies={policies}
                hidePolicies={hidePolicies} />
    </section>
}

const policies: Policies[] = [
    {
        name: "Privacy Policy",
        link: TWISTER_HOME + TWISTER_PRIVACY_POLICY_URL
    },
    {
        name: "Disclaimer",
        link: TWISTER_HOME + TWISTER_DISCLAIMER_URL
    }
]
