import {CARPOOL_THEME_COLOR} from "../screens/carpool/constants/Constatnt";
import Footer from "../footer/Footer";
import {PIXIE_MART_INDIA_EMAIL} from "../../constants/Constant";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import React from "react";
import {Button} from "react-bootstrap";

export function PixieMartSupport() {
    return <section className={"flex-fill d-flex flex-column"}>
        <div style={{background: CARPOOL_THEME_COLOR}}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Pixie Mart Support
                    </h1>
                    <p className={"fs-5"}>
                        Discover seamless support with Pixie Mart! Our dedicated team is here to assist you on your journey with us. Whether you have questions, feedback, or need technical assistance, we're just a message away. Elevate your experience with Pixie Mart's reliable and responsive support. 🧚‍♂️💬
                    </p>
                </div>
            </div>
        </div>

        <div className={"flex-fill"}>
            <div className={"container py-5 d-flex justify-content-center"}>
                <div className={"d-flex flex-column"}>
                    <a href={"mailto:" + PIXIE_MART_INDIA_EMAIL } rel="noreferrer" target="_blank" className={"no-text-decor"}>
                        <Button className={"my-1 fw-bold w-100"} variant={"dark"}>
                            <FontAwesomeIcon icon={["fab", "google"]} className={"me-1"} />
                            {PIXIE_MART_INDIA_EMAIL}
                        </Button>
                    </a>
                    <a href={"https://www.facebook.com/pixiemartindia"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                        <Button className={"my-1 fw-bold w-100"} variant={"dark"}>
                            <FontAwesomeIcon icon={["fab", "facebook"]} className={"me-1"} />
                            Facebook
                        </Button>
                    </a>
                    <a href={"https://www.instagram.com/pixiemartindia/"} rel="noreferrer" target="_blank" className={"no-text-decor"}>
                        <Button className={"my-1 fw-bold w-100"} variant={"dark"}>
                            <FontAwesomeIcon icon={["fab", "instagram"]} className={"me-1"} />
                            Instagram
                        </Button>
                    </a>
                </div>
            </div>
        </div>

        <Footer />
    </section>
}
