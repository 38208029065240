import Footer from "../../../footer/Footer";
import {CAR_POOL_DISCLAIMER_URL, CAR_POOL_FAQ_URL, CAR_POOL_HOME_URL, CAR_POOL_PRIVACY_POLICY_URL} from "../CarPoolUrl";
import {Policies} from "../../../footer/FooterInterface";

export function CarpoolFooter({hidePolicies}:{hidePolicies?: boolean;}) {
    return <section>
        <Footer contactUsName={"Pixie Carpool at Airport & Stations"}
                faqUrl={CAR_POOL_HOME_URL + CAR_POOL_FAQ_URL}
                policies={policies}
                hidePolicies={hidePolicies} />
    </section>
}

const policies: Policies[] = [
    {
        name: "Privacy Policy",
        link: CAR_POOL_HOME_URL + CAR_POOL_PRIVACY_POLICY_URL
    },
    {
        name: "Disclaimer",
        link: CAR_POOL_HOME_URL + CAR_POOL_DISCLAIMER_URL
    }
]
