import {TWISTER_THEME_COLOR} from "../constants/Constatnt";

export function AboutTwister() {
    return <div style={{background: TWISTER_THEME_COLOR}}>
        <div className={"container py-5 text-white"}>
            <div>
                <h2 className={"display-7 fw-bold"}>
                    Welcome to Pixie Mart - Tongue Twister
                </h2>
                <div>
                    <p className={"mb-4"}>
                        Embark on an enchanting journey to perfect your speech and fluency with our whimsical app! 🧚‍♂️✨
                    </p>
                    <h5 className={"fw-bold"}>
                        Twist and Turn Your Way to Speech Perfection 🎤
                    </h5>
                    <p>
                        Dive into a world of challenging tongue twisters that will put your pronunciation skills to the ultimate test. Unleash the power of your voice as you conquer the most delightful and tricky phrases.
                    </p>

                    <h5 className={"fw-bold"}>
                        Master the Challenge 🏆
                    </h5>
                    <p>
                        Challenge yourself with a myriad of tongue twisters, each more thrilling than the last. Can you conquer the twists and turns and emerge as the ultimate tongue twister master? Prove your prowess and earn your well-deserved crown!
                    </p>

                    <h5 className={"fw-bold"}>
                        Fun and Engaging Learning 🎭
                    </h5>
                    <p>
                        Experience the joy of learning with our playful pixie theme! The app is designed to make your language journey enjoyable, turning the often daunting task of pronunciation practice into a delightful adventure.
                    </p>

                    <h5 className={"fw-bold"}>
                        Record and Compare 🔊
                    </h5>
                    <p>
                        Record yourself taking on the challenge, then listen and compare your rendition with the correct pronunciation. It's the perfect way to fine-tune your skills and perfect the art of articulation.
                    </p>

                    <h5 className={"fw-bold"}>
                        Share the Fun 🌐
                    </h5>
                    <p>
                        Connect with friends and family by sharing your achievements and challenge them to beat your scores. Turn language learning into a social experience and let the friendly competition begin!
                    </p>

                    <h5 className={"fw-bold"}>
                        Download Tongue Twister Now 📲
                    </h5>
                    <p>
                        Embark on a magical journey to speech perfection. The adventure awaits – are you ready to twist and turn your way to fluency? 🚀✨
                    </p>
                </div>
            </div>
        </div>
    </div>
}
