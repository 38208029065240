import {TWISTER_THEME_COLOR} from "../constants/Constatnt";
import {HowItWorks} from "./HowItWorks";
import {PixieCarPoolAppDownload} from "./AndroidAppDownload";
import {AboutTwister} from "./AboutTwister";
import {TwisterFooter} from "../footer/TwisterFooter";

export function TwisterHome() {
    return <section>
        <div style={{background: TWISTER_THEME_COLOR}}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Pixie Mart
                    </h1>
                    <h2 className={"display-4 fw-bold"}>
                        Tongue Twister
                    </h2>
                    <p className={"fs-5"}>
                        Enhance your speech with Pixie Mart - Tongue Twister 🌟
                        Master the art of pronunciation through challenging twists and turns. Fun, engaging, and perfect for achieving fluency.
                        Start your journey to speech perfection today! 🧚‍♂️
                    </p>
                </div>

                <PixieCarPoolAppDownload />
            </div>
        </div>

        <div className={"d-flex justify-content-center bg-dark"}>
            <div className={"container py-2"}>
                <iframe src="https://www.youtube.com/embed/sTKigedQ-5U"
                        style={{aspectRatio: 16/9, width: '100%'}}
                        title="Pixie Mart - Tongue Twister (Now in Hindi)"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        allowFullScreen></iframe>
            </div>
        </div>

        <HowItWorks/>
        <AboutTwister/>

        <TwisterFooter />
    </section>
}
