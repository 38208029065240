import {CARPOOL_THEME_COLOR} from "../constants/Constatnt";
import {Accordion, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../constants/Constant";
import {CAR_POOL_HOME_URL, FAQ_URLS} from "../CarPoolUrl";
import {CarpoolFooter} from "../footer/CarpoolFooter";
import {SetCarPoolDocumentTitle} from "../../../../managers/DocumentTitleManager";
import {useEffect} from "react";

export function CarPoolFAQ() {
    const faqs = [
        {
            question: "How do I create a carpool on Pixie Mart?",
            answer: "To create a carpool, log in to your Pixie Mart carpool account, search for your city, select pickup and drop point, pickup time and click on \"Create or Search Pool.\". Once submitted, the pool will get created and visible to others."
        }, {
            question: "How can I join an existing carpool?",
            answer: "Browse through available carpools on the platform and request to join one that suits your preferences. The creator will review and approve your request. Once approved, you can start coordinating with fellow carpoolers through the chat functionality.",
        }, {
            question: "How does the approval process work for joining a carpool?",
            answer: "The carpool creator will receive a notification when you request to join. They will review your profile and preferences before approving or declining the request. This ensures a comfortable and compatible carpool experience for all members."
        }, {
            question: "Is there a chat feature for communication within the carpool?",
            answer: "Yes, Pixie Mart provides a built-in chat functionality for seamless communication within a carpool. Once your request is approved, you can use the chat feature to coordinate with other members regarding pickup points, timing, and any other details."
        }, {
            question: "Can I book a cab directly through Pixie Mart?",
            answer: "No, Pixie Mart Carpool does not facilitate direct cab bookings. Instead, members can coordinate through the platform and choose to book a cab via third-party providers like Uber, Quick-Ride, Ola, etc. This flexibility allows users to select their preferred transportation service."
        }, {
            question: "How do I coordinate pickup points with other carpool members?",
            answer: "Use the chat functionality within the carpool to discuss and finalize pickup points with other members. Clear communication is key to ensuring everyone is on the same page regarding meeting locations."
        }, {
            question: "What happens if I need to cancel my participation in a carpool?",
            answer: "If you need to cancel your participation, inform the carpool members through the chat and update your carpool status. It's important to communicate changes promptly to avoid inconvenience for other members."
        }, {
            question: "Can I edit the details of a carpool I've created?",
            answer: "No. your have to delete the carpool if it's created accidentally to avoid inconvenience to other fellows"
        }, {
            question: "How should I delete my account?",
            answer: "To delete your account you have to mail us at " + PIXIE_MART_INDIA_EMAIL + ". Once we review your request from the valid email id your account will get suspended within 24hr and will get deleted by 7 days.",
            know_more: {
                text: "More about deleting account",
                link: CAR_POOL_HOME_URL + FAQ_URLS.DELETE_ACCOUNT_FAQ_URL
            }
        }, {
            question: "What happens to my data if I delete my Pixie Mart account?",
            answer: "If you choose to delete your Pixie Mart Carpool account, your personal data will be anonymized within 7 days. Any ongoing or past carpool data will no longer be associated with your account."
        }, {
            question: "How do I report an issue or seek assistance?",
            answer: "For any issues or assistance, contact our support team at " + PIXIE_MART_INDIA_EMAIL + ". We're here to help and ensure a smooth experience for our users."
        }]

    useEffect(() => {
        SetCarPoolDocumentTitle("Frequently Asked Questions", "FAQ (Frequently Asked Questions) - Pixie Carpool");
    }, []);

    return <section>
        <div style={{background: CARPOOL_THEME_COLOR}}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Pixie Carpool - FAQ
                    </h1>
                    <p className={"fs-5"}>
                        Pixie mart carpool where you can create a pool for Airport and Stations from different
                        localities or vice versa.
                        The people traveling at same route can join your pool or you can join theirs and share the
                        amount, have a fun and enjoying the journey together.
                    </p>
                </div>
            </div>
        </div>
        <div className={"container pt-5 text-white"}>
            <Accordion flush alwaysOpen className={"no-acc-shadow"}>
                {
                    faqs.map((faq, index) => <FAQItem key={"faq_" + index} index={index} question={faq.question}
                                                      answer={faq.answer} know_more={faq.know_more}/>)
                }

            </Accordion>
        </div>

        <div className={"container pb-5 mt-4"}>
            <div className={"bg-white p-2"}>
                Have more queries reach out to us at <b>{PIXIE_MART_INDIA_EMAIL}</b> or any social media platform
                like <b>facebook</b> or <b>instagram</b>.
            </div>
        </div>

        <CarpoolFooter />
    </section>
}

function FAQItem({question, answer, index, know_more}:{question: string; answer: string; index:number; know_more?: {text: string; link: string;}}) {
    return <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>
            <div className={"fw-bold cursor-pointer"}>{question}</div>
        </Accordion.Header>
        <Accordion.Body>
            <div className={"pb-3"}>
                {answer}
            </div>
            {know_more &&
                <div>
                    <Link to={know_more.link}>
                        <Button>
                            {know_more.text}
                        </Button>
                    </Link>
                </div>
            }
        </Accordion.Body>
    </Accordion.Item>
}
