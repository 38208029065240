import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {SetEnlightenAIDocumentTitle} from "../../../../managers/DocumentTitleManager";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../constants/Constant";
import {AboutPixieEnlightenAI} from "./AboutPixieEnlightenAI";
import {InspireFooter} from "../footer/InspireFooter";

class EnlightenAIDisclaimerPolicy extends Component {

    componentDidMount() {
        SetEnlightenAIDocumentTitle("Disclaimer", "Disclaimer for Pixie Enlighten AI - Wisdom, Inspiration, and Motivation");
    }


    render() {
        return (
            <div className={"bg-white"}>
                <Container>
                    <div className={"p-2"}>
                        <h1 className={"fw-bold fs_heading"}>
                            Disclaimer for Pixie Enlighten AI - Wisdom, Inspiration, and Motivation
                        </h1>
                        <p className={"fw-bold text-secondary"}>
                            Last updated September 15, 2024
                        </p>

                        <div className={"pb-5"}>
                            <AboutPixieEnlightenAI/>
                        </div>

                        <h4 className={"fw-bold"}>
                            Introduction
                        </h4>

                        <p>
                            <b>Pixie Mart Enlighten AI</b> is designed to provide insights, guidance, and inspiration in
                            various aspects of personal growth, spirituality, and motivation. However, it's important to
                            understand the limitations of this service. Please review the following disclaimer carefully
                            before using Enlighten AI.
                        </p>

                        <h4 className={"fw-bold"}>
                            Enlighten AI Defined
                        </h4>

                        <p>
                            Enlighten AI is an artificial intelligence platform created to assist users by offering
                            general motivational content, inspiration, and insights on personal well-being and life
                            philosophy. It is not a replacement for professional mental health or life counseling.
                        </p>

                        <h4 className={"fw-bold"}>
                            Features
                        </h4>

                        <ul>
                            <li>
                                <b>Motivation</b>: Offers general advice, motivation, and inspiration in response to
                                user input.
                            </li>
                            <li>
                                <b>Spirituality & Purpose</b>: Provides information related to spirituality, life
                                purpose, and personal growth.
                            </li>
                            <li>
                                <b>Personal Support</b>: Acts as a supportive guide in personal reflection and
                                clarity-seeking.
                            </li>
                        </ul>

                        <p>Enlighten AI aims to foster positivity, wisdom, and understanding but should be used as a
                            supplementary tool, not a sole resource for critical life decisions.</p>

                        <h4 className={"fw-bold"}>
                            User Responsibilities
                        </h4>

                        <ul>
                            <li>
                                Users must understand that Enlighten AI is a general guide and not a licensed
                                professional counselor, therapist, or life coach.
                            </li>
                            <li>
                                Any advice or suggestions provided by Enlighten AI should be considered with personal
                                discernment and not be relied upon for making significant life decisions.
                            </li>
                            <li>
                                Users are encouraged to seek advice from qualified professionals (e.g., therapists,
                                spiritual leaders, or financial experts) when dealing with critical issues such as
                                mental health, medical, legal, or financial matters.
                            </li>
                        </ul>

                        <p>It is the user's responsibility to use Enlighten AI in a manner consistent with their needs
                            and context.</p>

                        <h4 className={"fw-bold"}>
                            General Disclaimer
                        </h4>

                        <ul>
                            <li>
                                <b>No Professional Advice</b>: Enlighten AI is not intended to provide medical,
                                psychological, legal, or financial advice. It serves as a general guide and motivational
                                tool.
                            </li>
                            <li>
                                <b>Use at Your Own Risk</b>: The advice and suggestions provided by Enlighten AI are
                                based on generalized algorithms and should not be interpreted as personalized
                                recommendations. Pixie Mart is not responsible for any decisions or actions taken based
                                on the input from Enlighten AI.
                            </li>
                            <li>
                                <b>Accuracy and Timeliness</b>: While Enlighten AI aims to provide helpful and
                                insightful content, it is not guaranteed to be accurate, comprehensive, or up-to-date.
                                Users should verify any information independently if they intend to rely on it.
                            </li>
                            <li>
                                <b>Liability Limitation</b>: Pixie Mart and Enlighten AI are not liable for any damages,
                                losses, or issues that arise from using the platform, including but not limited to
                                emotional distress, financial loss, or personal injury.
                            </li>
                            <li>
                                <b>Non-Replacement of Professional Services</b>: Enlighten AI is not a substitute for
                                professional services such as therapy, counseling, or medical advice. It is designed as
                                a tool for general guidance and reflection.
                            </li>
                        </ul>

                        <h4 className={"fw-bold"}>
                            Conclusion
                        </h4>

                        <p>
                            By using Pixie Mart Enlighten AI, you acknowledge that you have read and understood this
                            disclaimer and agree to use the service in accordance with these terms. The AI is designed
                            to offer inspiration and wisdom, but the responsibility for actions and decisions lies with
                            the user.
                        </p>

                        <div className={"my-5"}>
                            <h6>
                                For support or inquiries, please email at <a href={"mailto:" + PIXIE_MART_INDIA_EMAIL}
                                                                             className={"no-text-decor"}>{PIXIE_MART_INDIA_EMAIL}</a>.
                            </h6>
                            <h6 className={"fw-bold"}>
                                Thank you for using Pixie Enlighten AI
                            </h6>
                        </div>
                    </div>
                </Container>

                <InspireFooter/>
            </div>
        );
    }
}

export default EnlightenAIDisclaimerPolicy;
