import {AndroidAppDownload, IOSAppDownload} from "../home/AndroidAppDownload";
import {CARPOOL_THEME_COLOR} from "../constants/Constatnt";
import {useEffect} from "react";
import {CARPOOL_ANDROID_APP_URL, CARPOOL_IOS_APP_URL} from "../constants/URLConstant";
import {CarpoolFooter} from "../footer/CarpoolFooter";
import {SetCarPoolDocumentTitle} from "../../../../managers/DocumentTitleManager";

enum PlatformDevice {
    ANDROID, IOS, OTHER
}

export function CarpoolApplicationDownload() {
    useEffect(() => {
        SetCarPoolDocumentTitle("Download Application","Download Pixie Mart - Car Pool Application");

        if(getMobileOS() === PlatformDevice.ANDROID) {
            window.location.href = CARPOOL_ANDROID_APP_URL;
        } else if(getMobileOS() === PlatformDevice.IOS) {
            window.location.href = CARPOOL_IOS_APP_URL;
        }
    }, []);

    return <section className={"flex-fill d-flex flex-column"}>
        <div style={{background: CARPOOL_THEME_COLOR}} className={"flex-fill"}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Pixie Carpool
                    </h1>
                    <h2 className={"display-4 fw-bold"}>
                        at Airport & Stations
                    </h2>
                    <p className={"fs-5"}>
                        Pixie mart carpool where you can create a pool for Airport and Stations from different
                        localities or vice versa.
                        The people traveling at same route can join your pool or you can join theirs and share the
                        amount, have a fun and enjoying the journey together.
                    </p>
                </div>

                <div className={"d-flex my-5 flex-wrap"}>
                    <div className={"p-2 col-md-6"}>
                        <AndroidAppDownload />
                    </div>
                    <div className={"p-2 col-md-6"}>
                        <IOSAppDownload />
                    </div>
                </div>
            </div>
        </div>

        <CarpoolFooter />
    </section>
}

function getMobileOS() {
    const ua = navigator.userAgent;

    if (/android/i.test(ua)) {
        return PlatformDevice.ANDROID;
    }
    else if (/iPad|iPhone|iPod/.test(ua)){
        return PlatformDevice.IOS;
    }
    return PlatformDevice.OTHER;
}
