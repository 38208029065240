import {TWISTER_THEME_COLOR} from "../constants/Constatnt";
import {Accordion, Button} from "react-bootstrap";
import {Link} from "react-router-dom";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../constants/Constant";
import {TwisterFooter} from "../footer/TwisterFooter";
import {useEffect} from "react";
import {SetTongueTwisterDocumentTitle} from "../../../../managers/DocumentTitleManager";

export function TongueTwisterFAQ() {
    const faqs = [
        {
            question: "What is Pixie Mart - Tongue Twister?",
            answer: "Pixie Mart - Tongue Twister is a mobile application designed to help users improve their fluency and articulation in different languages through the practice of tongue twisters."
        }, {
            question: "How does the app work?",
            answer: "Users can select their preferred language and difficulty level within the app. They are then presented with a variety of tongue twisters to choose from. Once selected, users can use the speech-to-text functionality to attempt to articulate the tongue twister accurately. The app provides feedback on pronunciation and fluency."
        }, {
            question: "What languages are supported?",
            answer: "Pixie Mart - Tongue Twister supports a wide range of languages, including but not limited to English, Hindi"
        }, {
            question: "What levels of difficulty are available?",
            answer: "The app offers tongue twisters ranging from easy to advanced difficulty levels, catering to users of all proficiency levels."
        }, {
            question: "How does the speech-to-text functionality work?",
            answer: "The speech-to-text functionality transcribes the user's spoken words as they attempt to articulate the tongue twister. This allows users to compare their pronunciation with the correct pronunciation provided by the app."
        }, {
            question: "Can I track my progress?",
            answer: "Yes, the app provides progress tracking features, allowing users to monitor their improvement over time. Progress statistics may include accuracy, fluency, and speed of pronunciation."
        }, {
            question: "Is the app suitable for all ages?",
            answer: "Yes, Pixie Mart - Tongue Twister is suitable for users of all ages, from children learning a new language to adults looking to improve their pronunciation skills."
        }, {
            question: "Are there any in-app purchases?",
            answer: "It's all free to use the app"
        }, {
            question: "Can I use the app offline?",
            answer: "Some features of the app may require an internet connection, such as downloading new tongue twisters or accessing certain language packs. However, once downloaded, tongue twisters can typically be practiced offline. Read only mode for twister will be available"
        }, {
            question: "Is there a community aspect to the app?",
            answer: "Yes, Pixie Mart - Tongue Twister may include a community feature where users can share their progress, challenge each other."
        }
    ]

    useEffect(() => {
        SetTongueTwisterDocumentTitle("Frequently Asked Questions", "FAQ (Frequently Asked Questions) - Tongue Twister");
    }, []);

    return <section>
        <div style={{background: TWISTER_THEME_COLOR}}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Tongue Twister - FAQ
                    </h1>
                    <p className={"fs-5"}>
                        Enhance your speech with Pixie Mart - Tongue Twister 🌟 Master the art of pronunciation through
                        challenging twists and turns. Fun, engaging, and perfect for achieving fluency. Start your
                        journey to speech perfection today! 🧚‍♂️
                    </p>
                </div>
            </div>
        </div>
        <div className={"container pt-5 text-white"}>
            <Accordion flush alwaysOpen className={"no-acc-shadow"}>
                {
                    faqs.map((faq, index) => <FAQItem key={"faq_" + index} index={index} question={faq.question}
                                                      answer={faq.answer}/>)
                }

            </Accordion>
        </div>

        <div className={"container pb-5 mt-4"}>
            <div className={"bg-white p-2"}>
                Have more queries reach out to us at <b>{PIXIE_MART_INDIA_EMAIL}</b> or any social media platform
                like <b>facebook</b> or <b>instagram</b>.
            </div>
        </div>

        <TwisterFooter/>
    </section>
}

function FAQItem({question, answer, index, know_more}:{question: string; answer: string; index:number; know_more?: {text: string; link: string;}}) {
    return <Accordion.Item eventKey={index.toString()}>
        <Accordion.Header>
            <div className={"fw-bold cursor-pointer"}>{question}</div>
        </Accordion.Header>
        <Accordion.Body>
            <div className={"pb-3"}>
                {answer}
            </div>
            {know_more &&
                <div>
                    <Link to={know_more.link}>
                        <Button>
                            {know_more.text}
                        </Button>
                    </Link>
                </div>
            }
        </Accordion.Body>
    </Accordion.Item>
}
