import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {SetCarPoolDocumentTitle} from "../../../../managers/DocumentTitleManager";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../constants/Constant";
import {AboutPixieCarPool} from "./AboutPixieCarPool";
import {CarpoolFooter} from "../footer/CarpoolFooter";

class CarPoolDisclaimerPolicy extends Component {

    componentDidMount() {
        SetCarPoolDocumentTitle("Disclaimer", "Disclaimer for Pixie Mart Carpool at Airport & Stations");
    }


    render() {
        return (
            <div className={"bg-white"}>
                <Container>
                    <div className={"p-2"}>
                        <h1 className={"fw-bold fs_heading"}>
                            Disclaimer for Pixie Carpool at Airport & Stations
                        </h1>
                        <p className={"fw-bold text-secondary"}>
                            Last updated December 24, 2023
                        </p>

                        <div className={"pb-5"}>
                            <AboutPixieCarPool/>
                        </div>

                        <h4 className={"fw-bold"}>
                            Introduction
                        </h4>

                        <p>
                            <b>Pixie Carpool at Airport & Stations</b> is a mobile application designed to enhance
                            commuting experiences
                            by facilitating carpooling arrangements between users traveling to or from airports and
                            stations.
                            By using this app, you agree to comply with the terms and conditions outlined in this
                            disclaimer.
                            Please carefully read the following before using Pixie Carpool at Airport & Stations.
                        </p>

                        <h4 className={"fw-bold"}>
                            Pixie Carpool Defined
                        </h4>

                        <p>
                            Pixie Carpool is a platform where users can create and join pools for airport and station
                            travel from different localities.
                            It allows individuals traveling the same route to share rides, expenses, and enjoy the
                            journey together.
                        </p>

                        <h4 className={"fw-bold"}>
                            Features of Pixie Carpool
                        </h4>

                        <ul>
                            <li>
                                <b>Create and Join Pools</b>: Users can create pools for travel between airports or
                                stations
                                and various localities. Additionally, users can join existing pools to share rides and
                                costs.
                            </li>
                            <li>
                                <b>Co-Rider Approval</b>: Pool creators have the ability to approve or deny co-riders
                                for their
                                pool, ensuring a comfortable and secure commuting experience.
                            </li>
                            <li>
                                <b>Chat Functionality</b>: Users can communicate with co-riders through the in-app chat
                                feature. This allows them to coordinate pickup locations, timings, and decide on cab
                                booking services such as Uber, Ola, or Quick Ride.
                            </li>
                            <li>
                                <b>Accidental Pool Creation</b>: In the event of accidentally creating a pool, users
                                have the
                                option to delete the pool through the app.
                            </li>
                            <li>
                                <b>Automatic Pool Deletion</b>: Pools that are not in use and have surpassed 3 hours
                                past the
                                scheduled pickup time will be automatically deleted, optimizing the app's functionality.
                            </li>
                        </ul>

                        <h4 className={"fw-bold"}>
                            User Responsibilities
                        </h4>

                        <ul>
                            <li>
                                <b>Safety First</b>: Users are responsible for their safety during carpooling. Pixie
                                Carpool does not conduct background checks on users, and it is the responsibility of
                                users to exercise
                                caution and make informed decisions regarding their choice of co-riders.
                            </li>
                            <li>
                                <b>Identity Verification</b>: While Pixie Carpool encourages users to create profiles
                                with accurate information, it does not guarantee the identity of users. Users are
                                advised to verify the identity of co-riders through the in-app chat feature before
                                confirming a carpool.
                            </li>
                            <li>
                                <b>Emergency Situations</b>: In case of emergencies or accidents, users should contact
                                local authorities immediately. Pixie Carpool at Airport & Stations is not responsible
                                for any emergencies or accidents that may occur during carpooling.
                            </li>
                        </ul>

                        <h4 className={"fw-bold"}>
                            Chat Features
                        </h4>

                        <ul>
                            <li>
                                <b>Communication Guidelines</b>: The in-app chat feature is provided for users to
                                coordinate and communicate regarding carpool arrangements. Users are expected to engage
                                in respectful and appropriate communication. Any misuse of the chat feature, including
                                harassment or offensive language, may result in the suspension of the user's account.
                            </li>
                            <li>
                                <b>Privacy</b>: Users are advised not to share sensitive personal information, such as
                                home addresses or financial details, through the in-app chat. Pixie Carpool is not
                                responsible for any consequences arising from the sharing of such information.
                            </li>
                        </ul>

                        <h4 className={"fw-bold"}>
                            Booking a Cab
                        </h4>

                        <ul>
                            <li>
                                <b>Third-Party Services</b>: Pixie Carpool may integrate with third-party cab booking
                                services. Users should review and adhere to the terms and conditions of these services
                                before making bookings. Pixie Carpool is not responsible for the actions or services of
                                third-party providers.
                            </li>
                            <li>
                                <b>Cancellation Policy</b>: Users are encouraged to familiarize themselves with the
                                cancellation policies of the cab booking services integrated into Pixie Carpool. Pixie
                                Carpool is not responsible for any fees or charges incurred due to cancellations.
                            </li>
                        </ul>

                        <h4 className={"fw-bold"}>
                            General Disclaimer
                        </h4>

                        <p>
                            Pixie Carpool at Airport & Stations is a platform for users to coordinate carpooling
                            arrangements. The app does not own, operate, or control the vehicles used for carpooling.
                            Pixie Carpool at Airport & Stations disclaims any liability for damages, losses, or injuries
                            arising from the use of the app or participation in carpooling arrangements.
                        </p><p>
                        By using Pixie Carpool at Airport & Stations, you acknowledge that you have read, understood,
                        and agreed to the terms outlined in this disclaimer. Pixie Carpool at Airport & Stations
                        reserves the right to update or modify this disclaimer at any time without prior notice.
                    </p>

                        <div className={"my-5"}>
                            <h6>
                                For support or inquiries, please email at <a href={"mailto:" + PIXIE_MART_INDIA_EMAIL}
                                                                             className={"no-text-decor"}>{PIXIE_MART_INDIA_EMAIL}</a>.
                            </h6>
                            <h6 className={"fw-bold"}>
                                Thank you for using Pixie Carpool at Airport & Stations!
                            </h6>
                        </div>
                    </div>
                </Container>

                <CarpoolFooter />
            </div>
        );
    }
}

export default CarPoolDisclaimerPolicy;
