import React, {Component} from 'react';
import {Col, Container, Row} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {PIXIE_MART_INDIA_EMAIL} from "../../constants/Constant";
import {Policies} from "./FooterInterface";

interface Props {
    contactUsName?: string;
    hidePolicies?: boolean;
    policies?: Policies[];
    faqUrl?: string;
}

class Footer extends Component<Props> {
    render() {
        return (
            <section className={"bg-dark"} style={{background: "#343a40"}}>
                <Container className={"text-white p-4"}>
                    <Row className={"justify-content-between"}>
                        {
                            !this.props.hidePolicies && this.props.policies &&
                            <Col md={3}>
                                <div className={"py-2"}>
                                    <b>Policies</b>
                                    <div className={"text-secondary"}>
                                        {
                                            this.props.policies?.map((policy, index) => <Link key={"policy_" + index}
                                                                                              to={policy.link}
                                                                                              className={"no-text-decor"}>
                                                <p className={"m-0"}>{policy.name}</p>
                                            </Link>)
                                        }
                                    </div>
                                </div>
                            </Col>
                        }
                        <Col md={3}>
                            <div className={"py-2"}>
                                <b>Contact us</b>
                                <div className={"text-secondary"}>
                                    <p>{this.props.contactUsName ? this.props.contactUsName : "Pixie Mart"}</p>
                                    <a href={"mailto:" + PIXIE_MART_INDIA_EMAIL} rel="noreferrer" target="_blank"
                                       className={"no-text-decor"}>
                                        <p className={"my-1"}>
                                            <FontAwesomeIcon icon={["fab", "google"]} className={"me-1"}/>
                                            {PIXIE_MART_INDIA_EMAIL}
                                        </p>
                                    </a>
                                    <a href={"https://www.facebook.com/pixiemartindia"} rel="noreferrer" target="_blank"
                                       className={"no-text-decor"}>
                                        <p className={"my-1"}>
                                            <FontAwesomeIcon icon={["fab", "facebook"]} className={"me-1"}/>
                                            Facebook
                                        </p>
                                    </a>
                                    <a href={"https://www.instagram.com/pixiemartindia/"} rel="noreferrer"
                                       target="_blank" className={"no-text-decor"}>
                                        <p className={"my-1"}>
                                            <FontAwesomeIcon icon={["fab", "instagram"]} className={"me-1"}/>
                                            Instagram
                                        </p>
                                    </a>
                                </div>
                            </div>
                        </Col>
                        {
                            this.props.faqUrl &&
                            <Col md={3}>
                                <div className={"py-2"}>
                                    <b>Help</b>
                                    <div className={"text-secondary"}>
                                        <Link to={this.props.faqUrl} className={"no-text-decor"}>
                                            <p className={"m-0"}>FAQ</p>
                                        </Link>
                                    </div>
                                </div>
                            </Col>
                        }
                        <Col md={3}>
                            <div className={"py-2"}>
                                <b>Address</b>
                                <div className={"text-secondary"}>
                                    <p className={"m-0"}>Pixie Mart</p>
                                    <p className={"m-0"}>The Eco world</p>
                                    <p>Bengaluru, Karnataka, India</p>
                                    <p>560103</p>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <div className={"text-center text-secondary border-top border-secondary p-2"}>
                    {this.props.contactUsName && (this.props.contactUsName + " | ")} Pixie Mart
                </div>
            </section>
        );
    }
}

export default Footer;
