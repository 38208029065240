import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {SetEnlightenAIDocumentTitle} from "../../../../managers/DocumentTitleManager";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../constants/Constant";
import {AboutPixieEnlightenAI} from "./AboutPixieEnlightenAI";
import {InspireFooter} from "../footer/InspireFooter";

class EnlightenAIPrivacyPolicy extends Component {

    componentDidMount() {
        SetEnlightenAIDocumentTitle("Privacy Policy", "Privacy Policy for Pixie Mart Enlighten AI");
    }


    render() {
        return (
            <div className={"bg-white"}>
                <Container>
                    <div className={"p-2"}>
                        <h1 className={"fw-bold fs_heading"}>
                            Privacy Notice for Pixie Enlighten AI
                        </h1>
                        <p className={"fw-bold text-secondary"}>
                            Last updated September 15, 2024
                        </p>

                        <div className={"pb-5"}>
                            <AboutPixieEnlightenAI/>
                        </div>

                        <p>
                            This privacy notice for Enlighten AI ("<b>Company,</b>" "<b>we,</b>" "<b>us,</b>" or
                            "<b>our</b>"), describes how and why we might collect, store, use, and/or share
                            ("<b>process</b>") your information when you use our services ("<b>Services</b>"), such as
                            when you:
                        </p>

                        <ul>
                            <li>
                                Visit our website at Pixie-mart.com, or any website of ours that links to this privacy
                                notice
                            </li>
                            <li>
                                Download and use our mobile application (Pixie-mart), or any other application of ours
                                that links to this privacy notice
                            </li>
                            <li>
                                Engage with us in other related ways, including any sales, marketing, or events
                            </li>
                        </ul>
                        <p className={"fw-bold m-0"}>
                            Questions or concerns?
                        </p>
                        <p>
                            Reading this privacy notice will help you understand your privacy rights and choices. If you
                            do not agree with our policies and practices, please do not use our Services. If you still
                            have any questions or concerns, please contact us at Pixiemartindia@gmail.com.
                        </p>
                    </div>
                    <div className={"p-2"}>
                        <h2 className={"fs_heading2 fw-bold"}>
                            Summary of key points
                        </h2>
                        <p className={"fst-italic text-muted fw-bold"}>
                            This summary provides key points from our privacy notice, but you can find out more details
                            about any of these topics by clicking the link following each key point or by using our
                            table of contents below to find the section you are looking for. You can also click <a
                            href={"#table-of-content"}>here</a> to go directly to our table of contents.
                        </p>
                        <p className={"fw-bold m-0"}>
                            What personal information do we process?
                        </p>
                        <p>
                            When you visit, use, or navigate our Services, we may process personal information depending
                            on how you interact with Enlighten AI and the Services, the choices you make, and the
                            products
                            and features you use. Click <a href={"#personal-information"}>here</a> to learn more.
                        </p>
                        <p className={"fw-bold m-0"}>
                            Do we process any sensitive personal information?
                        </p>
                        <p>
                            We do not process sensitive personal information.
                        </p>
                        <p className={"fw-bold m-0"}>
                            Do we receive any information from third parties?
                        </p>
                        <p>
                            We do not receive any information from third parties.
                        </p>
                        <p className={"fw-bold m-0"}>How do we process your information? </p>
                        <p>
                            We process your information to provide, improve, and administer our Services, communicate
                            with you, for security and fraud prevention, and to comply with law. We may also process
                            your information for other purposes with your consent. We process your information only when
                            we have a valid legal reason to do so. Click <a href={"#how-do-we-process"}>here</a> to
                            learn more.
                        </p>
                        <p className={"fw-bold m-0"}>In what situations and with which parties do we share personal
                            information? </p>
                        <p>
                            We may share information in specific situations and with specific third parties. Click <a
                            href={"#whom-do-we-share"}>here</a>
                            to learn more.
                        </p>
                        <p className={"fw-bold m-0"}>How do we keep your information safe? </p>
                        <p>
                            We have organizational and technical processes and procedures in place to protect your
                            personal information. However, no electronic transmission over the internet or information
                            storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee
                            that hackers, cybercriminals, or other unauthorized third parties will not be able to defeat
                            our security and improperly collect, access, steal, or modify your information. Click <a
                            href={"#information-safe"}>here</a>
                            to learn more.
                        </p>
                        <p className={"fw-bold m-0"}>What are your rights? </p>
                        <p>
                            Depending on where you are located geographically, the applicable privacy law may mean you
                            have certain rights regarding your personal information. Click <a
                            href={"#your-rights"}>here</a> to learn more.
                        </p>
                        <p className={"fw-bold m-0"}>How do you exercise your rights? </p>
                        <p>
                            The easiest way to exercise your rights is by filling out our data subject request form
                            available here, or by contacting us. We will consider and act upon any request in accordance
                            with applicable data protection laws.
                        </p>
                        <p>
                            Want to learn more about what Enlighten AI does with any information we collect? Click <a
                            href={"#table-of-content"}>here</a> to review the notice in full.
                        </p>
                    </div>
                    <div className={"p-2"} id={"table-of-content"}>
                        <h2 className={"fs_heading2 fw-bold"}>
                            Table of contents
                        </h2>
                        <ul>
                            <li>What information do we collect?</li>
                            <li>How do we process your information?</li>
                            <li>When and with whom do we share your personal information?</li>
                            <li>Do we use cookies and other tracking technologies?</li>
                            <li>How do we handle your social logins?</li>
                            <li>How long do we keep your information?</li>
                            <li>How do we keep your information safe?</li>
                            <li>Do we collect information from minors?</li>
                            <li>What are your privacy rights?</li>
                            <li>Controls for do-not-track features</li>
                            <li>Do california residents have specific privacy rights?</li>
                            <li>Do we make updates to this notice?</li>
                            <li>How can you contact us about this notice?</li>
                            <li>How can you review, update, or delete the data we collect from you?</li>
                        </ul>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            1. What information do we collect?
                        </h3>
                        <p className={"fw-bold fs_75c_4"} id={"personal-information"}>
                            Personal information you disclose to us
                        </p>
                        <p>
                            <b>In Short:</b> We collect personal information that you provide to us.
                        </p>
                        <p>
                            We collect personal information that you voluntarily provide to us when you register on the
                            Services, express an interest in obtaining information about us or our products and
                            Services, when you participate in activities on the Services, or otherwise when you contact
                            us.
                        </p>
                        <p>
                            <b>Personal Information Provided by You.</b> The personal information that we collect
                            depends on the context of your interactions with us and the Services, the choices you make,
                            and the products and features you use. The personal information we collect may include the
                            following:
                        </p>
                        <ul>
                            <li>names</li>
                            <li>phone numbers</li>
                            <li>email addresses</li>
                            <li>usernames</li>
                            <li>passwords</li>
                            <li>billing addresses</li>
                            <li>contact preferences</li>
                            <li>mailing addresses</li>
                        </ul>
                        <p>
                            <b>Sensitive Information.</b> We do not process sensitive information.
                        </p>
                        <p>
                            <b>Social Media Login Data.</b> We may provide you with the option to register with us using
                            your existing social media account details, like your Facebook, Twitter, or other social
                            media account. If you choose to register in this way, we will collect the information
                            described in the section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS?" below.
                        </p>
                        <p>
                            <b>Application Data.</b> If you use our application(s), we also may collect the following
                            information if you choose to provide us with access or permission:
                        </p>
                        <ul>
                            <li>
                                Geolocation Information. We may request access or permission to track location-based
                                information from your mobile device, either continuously or while you are using our
                                mobile application(s), to provide certain location-based services. If you wish to change
                                our access or permissions, you may do so in your device's settings.
                            </li>
                            <li>
                                Push Notifications. We may request to send you push notifications regarding your account
                                or certain features of the application(s). If you wish to opt out from receiving these
                                types of communications, you may turn them off in your device's settings.
                            </li>
                        </ul>
                        <p>This information is primarily needed to maintain the security and operation of our
                            application(s), for troubleshooting, and for our internal analytics and reporting
                            purposes.</p>
                        <p>All personal information that you provide to us must be true, complete, and accurate, and you
                            must notify us of any changes to such personal information.</p>

                        <p className={"fw-bold fs_75c_4"}>
                            Information automatically collected
                        </p>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> Some information — such as your Internet Protocol (IP) address and/or
                            browser and device characteristics — is collected automatically when you visit our Services.
                        </p>

                        <p>We automatically collect certain information when you visit, use, or navigate the Services.
                            This information does not reveal your specific identity (like your name or contact
                            information) but may include device and usage information, such as your IP address, browser
                            and device characteristics, operating system, language preferences, referring URLs, device
                            name, country, location, information about how and when you use our Services, and other
                            technical information. This information is primarily needed to maintain the security and
                            operation of our Services, and for our internal analytics and reporting purposes.</p>

                        <p>Like many businesses, we also collect information through cookies and similar
                            technologies.</p>

                        <p>
                            The information we collect includes:
                        </p>
                        <ul>
                            <li>Log and Usage Data. Log and usage data is service-related, diagnostic, usage, and
                                performance information our servers automatically collect when you access or use our
                                Services and which we record in log files. Depending on how you interact with us, this
                                log data may include your IP address, device information, browser type, and settings and
                                information about your activity in the Services (such as the date/time stamps associated
                                with your usage, pages and files viewed, searches, and other actions you take such as
                                which features you use), device event information (such as system activity, error
                                reports (sometimes called "crash dumps"), and hardware settings).
                            </li>
                            <li>Device Data. We collect device data such as information about your computer, phone,
                                tablet, or other device you use to access the Services. Depending on the device used,
                                this device data may include information such as your IP address (or proxy server),
                                device and application identification numbers, location, browser type, hardware model,
                                Internet service provider and/or mobile carrier, operating system, and system
                                configuration information.
                            </li>
                            <li>Location Data. We collect location data such as information about your device's
                                location, which can be either precise or imprecise. How much information we collect
                                depends on the type and settings of the device you use to access the Services. For
                                example, we may use GPS and other technologies to collect geolocation data that tells us
                                your current location (based on your IP address). You can opt out of allowing us to
                                collect this information either by refusing access to the information or by disabling
                                your Location setting on your device. However, if you choose to opt out, you may not be
                                able to use certain aspects of the Services.
                            </li>
                        </ul>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"} id={"how-do-we-process"}>
                            2. How do we process your information?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> We process your information to provide, improve, and administer our
                            Services, communicate with you, for security and fraud prevention, and to comply with law.
                            We may also process your information for other purposes with your consent.
                        </p>
                        <p className={"fw-bold"}>
                            We process your personal information for a variety of reasons, depending on how you interact
                            with our Services, including:
                        </p>
                        <ul>
                            <li><b>To facilitate account creation and authentication and otherwise manage user
                                accounts.</b> We may process your information so you can create and log in to your
                                account, as well as keep your account in working order.
                            </li>
                            <li><b>To deliver and facilitate delivery of services to the user.</b> We may process your
                                information to provide you with the requested service.
                            </li>
                            <li><b>To respond to user inquiries/offer support to users.</b> We may process your
                                information to respond to your inquiries and solve any potential issues you might have
                                with the requested service.
                            </li>
                            <li><b>To fulfill and manage your orders.</b> We may process your information to fulfill and
                                manage your orders, payments, returns, and exchanges made through the Services.
                            </li>
                            <li><b>To request feedback.</b> We may process your information when necessary to request
                                feedback and to contact you about your use of our Services.
                            </li>
                            <li><b>To send you marketing and promotional communications.</b> We may process the personal
                                information you send to us for our marketing purposes, if this is in accordance with
                                your marketing preferences. You can opt out of our marketing emails at any time. For
                                more information, see "WHAT ARE YOUR PRIVACY RIGHTS?" below).
                            </li>
                            <li><b>To deliver targeted advertising to you.</b> We may process your information to
                                develop and display personalized content and advertising tailored to your interests,
                                location, and more.
                            </li>
                            <li><b>To protect our Services.</b> We may process your information as part of our efforts
                                to keep our Services safe and secure, including fraud monitoring and prevention.
                            </li>
                            <li><b>To evaluate and improve our Services, products, marketing, and your
                                experience.</b> We may process your information when we believe it is necessary to
                                identify usage trends, determine the effectiveness of our promotional campaigns, and to
                                evaluate and improve our Services, products, marketing, and your experience.
                            </li>
                            <li><b>To identify usage trends.</b> We may process information about how you use our
                                Services to better understand how they are being used so we can improve them.
                            </li>
                            <li><b>To comply with our legal obligations.</b> We may process your information to comply
                                with our legal obligations, respond to legal requests, and exercise, establish, or
                                defend our legal rights.
                            </li>
                        </ul>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"} id={"whom-do-we-share"}>
                            3. When and with whom do we share your personal information?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> We may share information in specific situations described in this section
                            and/or with the following third parties.
                        </p>
                        <p>
                            <b>Vendors, Consultants, and Other Third-Party Service Providers.</b> We may share your data
                            with third-party vendors, service providers, contractors, or agents ("third parties") who
                            perform services for us or on our behalf and require access to such information to do that
                            work. We have contracts in place with our third parties, which are designed to help
                            safeguard your personal information. This means that they cannot do anything with your
                            personal information unless we have instructed them to do it. They will also not share your
                            personal information with any organization apart from us. They also commit to protect the
                            data they hold on our behalf and to retain it for the period we instruct. The third parties
                            we may share personal information with are as follows:
                        </p>
                        <ul>
                            <li>
                                <b>Cloud Computing Services</b>
                                <p>
                                    Amazon Web Services (AWS)
                                </p>
                            </li>
                            <li>
                                <b>Content Optimization</b>
                                <p> Google Site Search</p>
                            </li>
                            <li>
                                <b>Web and Mobile Analytics</b>
                                <p> Google Analytics</p>
                            </li>
                        </ul>
                        <p>
                            We also may need to share your personal information in the following situations:
                        </p>
                        <ul>
                            <li><b>Business Transfers.</b> We may share or transfer your information in connection with,
                                or during negotiations of, any merger, sale of company assets, financing, or acquisition
                                of all or a portion of our business to another company.
                            </li>
                            <li><b>When we use Google Maps Platform APIs.</b> We may share your information with certain
                                Google Maps Platform APIs (e.g., Google Maps API, Places API). To find out more about
                                Google’s Privacy Policy, please refer to this link. We obtain and store on your device
                                ("cache") your location. You may revoke your consent anytime by contacting us at the
                                contact details provided at the end of this document.
                            </li>
                            <li><b>Offer Wall.</b> Our application(s) may display a third-party hosted "offer wall."
                                Such an offer wall allows third-party advertisers to offer virtual currency, gifts, or
                                other items to users in return for the acceptance and completion of an advertisement
                                offer. Such an offer wall may appear in our application(s) and be displayed to you based
                                on certain data, such as your geographic area or demographic information. When you click
                                on an offer wall, you will be brought to an external website belonging to other persons
                                and will leave our application(s). A unique identifier, such as your user ID, will be
                                shared with the offer wall provider in order to prevent fraud and properly credit your
                                account with the relevant reward.
                            </li>
                        </ul>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            4. Do we use cookies and other tracking technologies?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> We may use cookies and other tracking technologies to collect and store
                            your information.
                        </p>
                        <p>We may use cookies and similar tracking technologies (like web beacons and pixels) to access
                            or store information. Specific information about how we use such technologies and how you
                            can refuse certain cookies is set out in our Cookie Notice.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            5. How do we handle your social logins?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> If you choose to register or log in to our Services using a social media
                            account, we may have access to certain information about you.
                        </p>
                        <p>Our Services offer you the ability to register and log in using your third-party social media
                            account details (like your Facebook or Twitter logins). Where you choose to do this, we will
                            receive certain profile information about you from your social media provider. The profile
                            information we receive may vary depending on the social media provider concerned, but will
                            often include your name, email address, friends list, and profile picture, as well as other
                            information you choose to make public on such a social media platform.</p>
                        <p>We will use the information we receive only for the purposes that are described in this
                            privacy notice or that are otherwise made clear to you on the relevant Services. Please note
                            that we do not control, and are not responsible for, other uses of your personal information
                            by your third-party social media provider. We recommend that you review their privacy notice
                            to understand how they collect, use, and share your personal information, and how you can
                            set your privacy preferences on their sites and apps.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            6. How long do we keep your information?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> We keep your information for as long as necessary to fulfill the purposes
                            outlined in this privacy notice unless otherwise required by law.
                        </p>
                        <p>We will only keep your personal information for as long as it is necessary for the purposes
                            set out in this privacy notice, unless a longer retention period is required or permitted by
                            law (such as tax, accounting, or other legal requirements). No purpose in this notice will
                            require us keeping your personal information for longer than the period of time in which
                            users have an account with us.</p>
                        <p>When we have no ongoing legitimate business need to process your personal information, we
                            will either delete or anonymize such information, or, if this is not possible (for example,
                            because your personal information has been stored in backup archives), then we will securely
                            store your personal information and isolate it from any further processing until deletion is
                            possible.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"} id={"information-safe"}>
                            7. How do we keep your information safe?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> We aim to protect your personal information through a system of
                            organizational and technical security measures.
                        </p>
                        <p>We have implemented appropriate and reasonable technical and organizational security measures
                            designed to protect the security of any personal information we process. However, despite
                            our safeguards and efforts to secure your information, no electronic transmission over the
                            Internet or information storage technology can be guaranteed to be 100% secure, so we cannot
                            promise or guarantee that hackers, cybercriminals, or other unauthorized third parties will
                            not be able to defeat our security and improperly collect, access, steal, or modify your
                            information. Although we will do our best to protect your personal information, transmission
                            of personal information to and from our Services is at your own risk. You should only access
                            the Services within a secure environment.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            8. Do we collect information from minors?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> We do not knowingly collect data from or market to children under 18 years
                            of age.
                        </p>
                        <p>We do not knowingly solicit data from or market to children under 18 years of age. By using
                            the Services, you represent that you are at least 18 or that you are the parent or guardian
                            of such a minor and consent to such minor dependent’s use of the Services. If we learn that
                            personal information from users less than 18 years of age has been collected, we will
                            deactivate the account and take reasonable measures to promptly delete such data from our
                            records. If you become aware of any data we may have collected from children under age 18,
                            please contact us at {PIXIE_MART_INDIA_EMAIL}.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"} id={"your-rights"}>
                            9. What are your privacy rights?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> You may review, change, or terminate your account at any time.
                        </p>
                        <p>If you are located in the EEA or UK and you believe we are unlawfully processing your
                            personal information, you also have the right to complain to your local data protection
                            supervisory authority. You can find their contact details here:
                            https://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.</p>
                        <p>If you are located in Switzerland, the contact details for the data protection authorities
                            are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.</p>
                        <p><b>Withdrawing your consent:</b> If we are relying on your consent to process your personal
                            information, which may be express and/or implied consent depending on the applicable law,
                            you have the right to withdraw your consent at any time. You can withdraw your consent at
                            any time by contacting us by using the contact details provided in the section "HOW CAN YOU
                            CONTACT US ABOUT THIS NOTICE?" below.</p>
                        <p>However, please note that this will not affect the lawfulness of the processing before its
                            withdrawal nor, when applicable law allows, will it affect the processing of your personal
                            information conducted in reliance on lawful processing grounds other than consent.</p>
                        <p className={"fw-bold fs_75c_4"}>
                            Account Information
                        </p>
                        <p>If you would at any time like to review or change the information in your account or
                            terminate your account, you can:</p>
                        <ul>
                            <li>Log in to your account settings and update your user account.</li>
                            <li>Contact us using the contact information provided.</li>
                        </ul>
                        <p>
                            Upon your request to terminate your account, we will deactivate or delete your account and
                            information from our active databases. However, we may retain some information in our files
                            to prevent fraud, troubleshoot problems, assist with any investigations, enforce our legal
                            terms and/or comply with applicable legal requirements.
                        </p>
                        <p>
                            <b>Cookies and similar technologies:</b> Most Web browsers are set to accept cookies by
                            default. If you prefer, you can usually choose to set your browser to remove cookies and to
                            reject cookies. If you choose to remove cookies or reject cookies, this could affect certain
                            features or services of our Services. To opt out of interest-based advertising by
                            advertisers on our Services visit http://www.aboutads.info/choices/.
                        </p>
                        <p>
                            If you have questions or comments about your privacy rights, you may email us
                            at {PIXIE_MART_INDIA_EMAIL}.
                        </p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            10. Controls for do-not-track features
                        </h3>
                        <p>Most web browsers and some mobile operating systems and mobile applications include a
                            Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference
                            not to have data about your online browsing activities monitored and collected. At this
                            stage no uniform technology standard for recognizing and implementing DNT signals has been
                            finalized. As such, we do not currently respond to DNT browser signals or any other
                            mechanism that automatically communicates your choice not to be tracked online. If a
                            standard for online tracking is adopted that we must follow in the future, we will inform
                            you about that practice in a revised version of this privacy notice.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            11. Do california residents have specific privacy rights?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> Yes, if you are a resident of California, you are granted specific rights
                            regarding access to your personal information.
                        </p>
                        <p>California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
                            users who are California residents to request and obtain from us, once a year and free of
                            charge, information about categories of personal information (if any) we disclosed to third
                            parties for direct marketing purposes and the names and addresses of all third parties with
                            which we shared personal information in the immediately preceding calendar year. If you are
                            a California resident and would like to make such a request, please submit your request in
                            writing to us using the contact information provided below.</p>
                        <p>If you are under 18 years of age, reside in California, and have a registered account with
                            Services, you have the right to request removal of unwanted data that you publicly post on
                            the Services. To request removal of such data, please contact us using the contact
                            information provided below and include the email address associated with your account and a
                            statement that you reside in California. We will make sure the data is not publicly
                            displayed on the Services, but please be aware that the data may not be completely or
                            comprehensively removed from all our systems (e.g., backups, etc.).</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            12. Do we make updates to this notice?
                        </h3>
                        <p className={"fst-italic"}>
                            <b>In Short:</b> Yes, we will update this notice as necessary to stay compliant with
                            relevant laws.
                        </p>
                        <p>We may update this privacy notice from time to time. The updated version will be indicated by
                            an updated "Revised" date and the updated version will be effective as soon as it is
                            accessible. If we make material changes to this privacy notice, we may notify you either by
                            prominently posting a notice of such changes or by directly sending you a notification. We
                            encourage you to review this privacy notice frequently to be informed of how we are
                            protecting your information.</p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            13. How can you contact us about this notice?
                        </h3>
                        <p>If you have questions or comments about this notice, you may email us
                            at {PIXIE_MART_INDIA_EMAIL} or by post to:</p>
                        <p>
                            Pixie Enlighten AI <br/>
                            The Eco world <br/>
                            Bengaluru, Karnataka, India <br/>
                            560103
                        </p>
                    </div>
                    <div className={"p-2"}>
                        <h3 className={"fw-bold fs_75c_10"}>
                            14. How can you review, update, or delete the data we collect from you?
                        </h3>
                        <p>You have the right to request access to the personal information we collect from you, change
                            that information, or delete it. To request to review, update, or delete your personal
                            information, please submit a request form by clicking here.</p>
                    </div>
                </Container>

                <InspireFooter />
            </div>
        );
    }
}

export default EnlightenAIPrivacyPolicy;
