import {isNullOrUndefinedObject} from "../utils/validators/Validator";
import twisterIcon from "../components/screens/twister/statics/images/icons/twister_icon.png";
import carPoolIcon from "../components/screens/carpool/statics/images/icons/carpool_icon.png";
import inspireIcon from "../components/screens/inspire/statics/images/icons/inspire_icon.png";

// Quality check - https://freetools.seobility.net/en/serp-snippet-generator/?url=https://bicycle.pixie-mart.com/&platform=desktop
export function SetMainDocumentTitle(title: string, metaDesc: string) {
    window.document.title = "Pixie Mart | " + title;
    if(!isNullOrUndefinedObject(metaDesc) && document != null) {
        document.querySelector('meta[name="description"]')?.setAttribute("content", metaDesc);
    }
}

export function SetTongueTwisterDocumentTitle(title: string, metaDesc: string) {
    SetMainDocumentTitle("Tongue Twister - " + title, metaDesc);
    SetFaviconIcon(twisterIcon);
}

export function SetCarPoolDocumentTitle(title: string, metaDesc: string) {
    SetMainDocumentTitle("Pixie Carpool - " + title, metaDesc);
    SetFaviconIcon(carPoolIcon);
}

export function SetEnlightenAIDocumentTitle(title: string, metaDesc: string) {
    SetMainDocumentTitle("Enlighten AI - " + title, metaDesc);
    SetFaviconIcon(inspireIcon);
}

function SetFaviconIcon(icon: string) {
    let link = document.querySelector("link[rel~='icon']");
    if(link) {
        link.setAttribute("href", icon);
    }
}
