import {TWISTER_ANDROID_APP_URL, TWISTER_IOS_APP_URL} from "../constants/URLConstant";
import {Link} from "react-router-dom";
import {TWISTER_APP_DOWNLOAD_URL, TWISTER_HOME} from "../TwisterUrl";
import twister_qr_code_home from "../statics/images/twister_qr_code_home.webp";
import twister_qr_code_android from "../statics/images/twister_qr_code_android.webp";

export function AndroidAppDownload() {
    return <a rel={"noreferrer"} className={"no-text-decor"}
              href={TWISTER_ANDROID_APP_URL} target={"_blank"} >
        <div className={"d-flex h-100"}>
            <div className={"d-flex shadow bg-white"}>
                <div className={"p-2"}>
                    <img height={160} src={twister_qr_code_android} alt={"android_qr_code"} />
                </div>
                <div className={"text-dark p-3 d-flex align-items-center"}>
                    <div>
                        <h4 className={"fw-bold"}>
                            Download for Android
                        </h4>
                        <p>
                            Click or Scan QR code to download the app in Android phone
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>
}

export function IOSAppDownload() {
    return <a rel={"noreferrer"} className={"no-text-decor"}
              href={TWISTER_IOS_APP_URL} target={"_blank"} >
        <div className={"d-flex h-100"}>
            <div className={"d-flex shadow bg-white"}>
                <div className={"p-2"}>
                    <img height={160} src={twister_qr_code_android} alt={"android_qr_code"} />
                </div>
                <div className={"text-dark p-3 d-flex align-items-center"}>
                    <div>
                        <h4 className={"fw-bold"}>
                            Download For iPhone
                        </h4>
                        <p>
                            Click or Scan QR code to download the app in iPhone
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>
}

export function PixieCarPoolAppDownload() {
    return <Link className={"no-text-decor"} to={TWISTER_HOME + TWISTER_APP_DOWNLOAD_URL} >
        <div className={"d-flex my-5"}>
            <div className={"d-flex shadow bg-white"}>
                <div className={"p-2"}>
                    <img height={160} src={twister_qr_code_home} alt={"android_qr_code"} />
                </div>
                <div className={"text-dark p-3 d-flex align-items-center"}>
                    <div>
                        <h4 className={"fw-bold"}>
                            Download Pixie Mart - Tongue Twister app
                        </h4>
                        <p>
                            Click or Scan QR code to download the application
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Link>
}
