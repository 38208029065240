import React, {Component} from 'react';
import {Container} from "react-bootstrap";
import {SetTongueTwisterDocumentTitle} from "../../../../managers/DocumentTitleManager";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../constants/Constant";
import {AboutPixieTongueTwister} from "./AboutPixieTongueTwister";
import {TwisterFooter} from "../footer/TwisterFooter";

class TongueTwisterDisclaimerPolicy extends Component {

    componentDidMount() {
        SetTongueTwisterDocumentTitle("Disclaimer", "Disclaimer for Pixie Mart - Tongue Twister");
    }


    render() {
        return (
            <div className={"bg-white"}>
                <Container>
                    <div className={"p-2"}>
                        <h1 className={"fw-bold fs_heading"}>
                            Disclaimer for Pixie Mart - Tongue Twister
                        </h1>
                        <p className={"fw-bold text-secondary"}>
                            Last updated December 24, 2023
                        </p>

                        <div className={"pb-5"}>
                            <AboutPixieTongueTwister/>
                        </div>

                        <p>
                            The "Tongue Twister" app is designed for entertainment and educational purposes only. While
                            the app aims to help users improve their fluency and articulation through the practice of
                            tongue twisters, it is not intended to replace professional language instruction or speech
                            therapy.
                        </p>

                        <p>
                            Users should be aware that practicing tongue twisters may pose a challenge and could
                            potentially lead to frustration or difficulty in pronunciation. It is recommended that users
                            practice tongue twisters at their own pace and within their comfort level.
                        </p>
                        <p>
                            The app developers cannot guarantee the accuracy of speech-to-text functionality or the
                            correctness of pronunciation feedback provided by the app. Users should use their own
                            judgment
                            and seek additional resources or instruction if needed.
                        </p>
                        <p>
                            The "Tongue Twister" app is not responsible for any misunderstandings, misinterpretations,
                            or
                            mispronunciations that may occur during the use of the app. Users assume full responsibility
                            for
                            their use of the app and any consequences that may result from practicing tongue twisters.
                        </p>
                        <p>
                            By using the "Tongue Twister" app, users acknowledge and accept these terms and conditions.
                            If
                            you have any concerns or questions about the app, please contact us for assistance.
                        </p>

                        <div className={"my-5"}>
                            <h6>
                                For support or inquiries, please email at <a href={"mailto:" + PIXIE_MART_INDIA_EMAIL}
                                                                             className={"no-text-decor"}>{PIXIE_MART_INDIA_EMAIL}</a>.
                            </h6>
                            <h6 className={"fw-bold"}>
                                Thank you for using Pixie Mart - Tongue Twister
                            </h6>
                        </div>
                    </div>
                </Container>

                <TwisterFooter />
            </div>
        );
    }
}

export default TongueTwisterDisclaimerPolicy;
