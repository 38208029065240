import {AndroidAppDownload, IOSAppDownload} from "../home/AndroidAppDownload";
import {TWISTER_THEME_COLOR} from "../constants/Constatnt";
import {useEffect} from "react";
import {TWISTER_ANDROID_APP_URL, TWISTER_IOS_APP_URL} from "../constants/URLConstant";
import {TwisterFooter} from "../footer/TwisterFooter";
import {SetTongueTwisterDocumentTitle} from "../../../../managers/DocumentTitleManager";

enum PlatformDevice {
    ANDROID, IOS, OTHER
}

export function TwisterApplicationDownload() {
    useEffect(() => {
        SetTongueTwisterDocumentTitle("Download Application","Download Pixie Mart - Tongue Twister Application");
        if(getMobileOS() === PlatformDevice.ANDROID) {
            window.location.href = TWISTER_ANDROID_APP_URL;
        } else if(getMobileOS() === PlatformDevice.IOS) {
            window.location.href = TWISTER_IOS_APP_URL;
        }
    }, []);

    return <section className={"flex-fill d-flex flex-column"}>
        <div style={{background: TWISTER_THEME_COLOR}} className={"flex-fill"}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        Pixie Mart
                    </h1>
                    <h2 className={"display-4 fw-bold"}>
                        Tongue Twister
                    </h2>
                    <p className={"fs-5"}>
                        Enhance your speech with Pixie Mart - Tongue Twister 🌟
                        Master the art of pronunciation through challenging twists and turns. Fun, engaging, and perfect for achieving fluency.
                        Start your journey to speech perfection today! 🧚‍♂️
                    </p>
                </div>

                <div className={"d-flex my-5 flex-wrap"}>
                    <div className={"p-2 col-md-6"}>
                        <AndroidAppDownload />
                    </div>
                    <div className={"p-2 col-md-6"}>
                        <IOSAppDownload />
                    </div>
                </div>
            </div>
        </div>

        <TwisterFooter />
    </section>
}

function getMobileOS() {
    const ua = navigator.userAgent;

    if (/android/i.test(ua)) {
        return PlatformDevice.ANDROID;
    }
    else if (/iPad|iPhone|iPod/.test(ua)){
        return PlatformDevice.IOS;
    }
    return PlatformDevice.OTHER;
}
