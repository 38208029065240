import screen01 from "../statics/images/phones/screen01.webp";
import screen02 from "../statics/images/phones/screen02.webp";
import screen03 from "../statics/images/phones/screen03.webp";
import screen04 from "../statics/images/phones/screen04.webp";
import {CARPOOL_THEME_COLOR} from "../constants/Constatnt";

const screens = [
    {
        image: screen01,
        content: "Create or Search for your pool"
    },
    {
        image: screen02,
        content: "Pool got created successfully, wait for someone to join"
    },
    {
        image: screen03,
        content: "You got a request from someone"
    },
    {
        image: screen04,
        content: "Approve and chat with him to decide pickup location and time"
    },
]

export function HowItWorks() {
    return <div className={"py-5 bg-white"}>
        <div className={"container"}>
            <h2 className={"display-6 fw-bold py-4"}>
                How Pixie Carpool works ?
            </h2>
            <div className={"d-flex flex-wrap"}>
                {
                    screens.map((screen, index) => {
                        return <div key={"screen_" + index} className={"p-1 col-md-3 col-6"}>
                            <div className={"h-100 p-2 rounded"} style={{background: CARPOOL_THEME_COLOR}}>
                                <div className={"rounded overflow-hidden"}>
                                    <img src={screen.image} className={"img-object-fit-cover"} alt={"phone_screen_" + index} />
                                </div>
                                <div className={"text-white fw-bold"}>
                                    {screen.content}
                                </div>
                            </div>
                        </div>
                    })
                }
            </div>
        </div>
    </div>;
}
