import {CARPOOL_THEME_COLOR} from "../../constants/Constatnt";
import {PIXIE_MART_INDIA_EMAIL} from "../../../../../constants/Constant";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {CarpoolFooter} from "../../footer/CarpoolFooter";
import {useEffect} from "react";
import {SetCarPoolDocumentTitle} from "../../../../../managers/DocumentTitleManager";

export function DeleteAccountFAQ() {

    useEffect(() => {
        SetCarPoolDocumentTitle("Delete Account", "Delete account for - Pixie Carpool");
    }, []);

    return <section>
        <div style={{background: CARPOOL_THEME_COLOR}}>
            <div className={"container py-5 text-white"}>
                <div>
                    <h1 className={"display-3 fw-bold"}>
                        How should I delete my account?
                    </h1>
                    <h5>
                        Pixie Mart Carpool
                    </h5>
                    <p className={"fs-5"}>
                        We're sorry to see you go! If you wish to delete your Pixie Mart Carpool account, please follow
                        the steps below.
                    </p>
                </div>
            </div>
        </div>
        <div className={"container py-5"}>
            <div className={"mt-4"}>
                <h4 className={"fw-bold"}>
                    Before you proceed
                </h4>
                <ul>
                    <li>Your all pool data and history will be deleted.</li>
                    <li>Your account information and ride history will be permanently deleted.</li>
                </ul>
            </div>

            <div className={"mt-4"}>
                <h4 className={"fw-bold"}>
                    Raise Delete Request
                </h4>
                <div>
                    <Link
                        to={"mailto:" + PIXIE_MART_INDIA_EMAIL + "?subject=Delete my pixie carpool account&body=Hi Pixie, help me delete with my pixie carpool account.\n\n Email: <EMAIL> \n Reason: <REASON>"}>
                        <Button variant={"danger"}>
                            <FontAwesomeIcon icon={"envelope"}/>{' '}
                            Delete account request mail
                        </Button>
                    </Link>
                    <div>
                        or email at {PIXIE_MART_INDIA_EMAIL} with subject "Delete my pixie carpool account"
                    </div>
                </div>
            </div>

            <div className={"mt-4"}>
                <h4 className={"fw-bold"}>
                    What happens next
                </h4>
                <ul>
                    <li>Your account will be deactivated within 24hr, and all personal information will be anonymized
                        within 7 days.
                    </li>
                    <li>If you change your mind during this period, you can reactivate your account by logging in.</li>
                </ul>
            </div>

            <div className={"mt-4"}>
                <h4 className={"fw-bold"}>
                    Need Help?
                </h4>
                <ul>
                    <li>If you encounter any issues or have questions, please contact our support team
                        at {PIXIE_MART_INDIA_EMAIL}.
                    </li>
                </ul>
            </div>

            <div>
                Thank you for being a part of Pixie Mart Carpool. We hope to see you again in the future!
            </div>

        </div>

        <CarpoolFooter />
    </section>
}
