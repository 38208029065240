import {CARPOOL_THEME_COLOR} from "../carpool/constants/Constatnt";
import {Button} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link} from "react-router-dom";
import {CAR_POOL_HOME_URL} from "../carpool/CarPoolUrl";
import Footer from "../../footer/Footer";
import {TWISTER_HOME} from "../twister/TwisterUrl";
import {BICYCLE_COMPANY_URL} from "../../../constants/Constant";
import {INSPIRE_HOME_URL} from "../inspire/InspireUrl";
import buddha from "../inspire/statics/buddha.jpg";
import carpool from "../carpool/statics/images/carpool.webp";
import tongue_twister from "../twister/statics/images/tonguetwister.webp";
import bicycle_shop from "../bicycle/statics/images/pixie_mart_shop.png";

export function StaticWebHome() {
    return <section>
        <AboutPixieMart/>

        <InspireApplication/>
        <CarpoolingApplication/>
        <TongueTwisterApplication/>
        <BicycleApplication/>
        <PixieMartMission/>
        <Footer/>
    </section>
}

export function AboutPixieMart() {
    return <div style={{background: CARPOOL_THEME_COLOR}}>
        <div className={"container py-5 text-white"}>
            <div>
                <h1 className={"display-3 fw-bold"}>
                    Pixie Mart
                </h1>
                <h4>
                    Connecting Communities, Sparking Innovation: Welcome to Pixie Mart
                </h4>
                <p className={"fs-5"}>
                    At Pixie Mart, we pride ourselves on being a dynamic and versatile technical company that
                    thrives on innovation and connectivity. With a diverse range of products and services, we cater
                    to the needs of modern-day individuals seeking convenience, entertainment, and sustainable
                    solutions.
                </p>
            </div>
        </div>
    </div>
}

function InspireApplication() {
    return <ApplicationBlock
        applicationName={"Enlighten AI"}
        appContent={"Your personal guide to understanding the true meaning of life. In our lives, we " +
            "have good mentors and people who show us the right path, but sometimes we hesitate to share our problems " +
            "and suffer internally. Enlighten AI steps in as a compassionate mentor, offering inspiration, motivation, " +
            "and wisdom to help you find happiness and clarity, gently guiding you on the right path."}
        websiteURL={INSPIRE_HOME_URL}
        image={buddha}
    />
}

function CarpoolingApplication() {
    return <ApplicationBlock
        applicationName={"Carpool"}
        appContent={"One of our flagship products is our cutting-edge carpooling application. Designed to foster community" +
            " engagement and reduce carbon footprint, our app allows users to seamlessly connect, chat, and coordinate" +
            " rides through third-party platforms like User, Quick Ride, and Rapido. Whether it's sharing rides to work," +
            " events, or daily errands, our platform facilitates efficient and cost-effective transportation solutions" +
            " while promoting social interaction and environmental consciousness."}
        websiteURL={CAR_POOL_HOME_URL}
        image={carpool}
    />
}

function TongueTwisterApplication() {
    return <ApplicationBlock
        applicationName={"Tongue Twister"}
        appContent={"In addition to practical solutions, we believe in the importance of fun and personal development. Our Tongue" +
            " Twister application offers users a playful way to enhance their linguistic skills and fluency. With an" +
            " extensive collection of tongue twisters and interactive features, users can challenge themselves, track" +
            " their progress, and compete with friends to master the art of pronunciation and diction. Whether you're a" +
            " language enthusiast or simply looking for a good laugh, Tongue Twister provides endless entertainment and" +
            " educational value."}
        websiteURL={TWISTER_HOME}
        image={tongue_twister}
    />
}

function BicycleApplication() {
    return <ApplicationBlock
        applicationName={"Bicycle Company"}
        appContent={"At Pixie Mart, we're committed to promoting eco-friendly alternatives to traditional transportation. " +
            "Through our Bicycle Company platform, users can access a curated marketplace for buying and selling refurbished " +
            "bicycles. Whether you're a seasoned cyclist or a beginner looking to embrace a greener lifestyle, our platform " +
            "offers a convenient and sustainable way to find high-quality bicycles at affordable prices. By encouraging the " +
            "reuse and repurposing of bicycles, we aim to reduce waste and promote the widespread adoption of cycling as a " +
            "viable mode of transportation."}
        websiteURL = {BICYCLE_COMPANY_URL}
        image={bicycle_shop}
    />
}

function ApplicationBlock(props: {applicationName: string, appContent: string, websiteURL: string, image?: string}) {
    return <div className={"container py-5"}>
        <div>
            <h2 className={"display-5 fw-bold"}>
                {props.applicationName}
            </h2>
            {props.image &&
                <div className={"col-md-6 py-2"}>
                    <img src={props.image} alt={"buddha"} className={"bg-white img-object-fit-cover rounded"}/>
                </div>
            }
            <p className={"fs-5"}>
                {props.appContent}
            </p>
            <div>
                <Link to={props.websiteURL}>
                    <Button>
                        <FontAwesomeIcon icon={"info-circle"}/> More about {props.applicationName}
                    </Button>
                </Link>
            </div>
        </div>
    </div>
}

function PixieMartMission() {
    return <div style={{background: CARPOOL_THEME_COLOR}}>
        <div className={"container py-5 text-white"}>
            <p className={"fs-5"}>
                At Pixie Mart, our mission is to harness technology to create solutions that enrich lives, foster
                connections, and contribute to a more sustainable future. With a focus on innovation, accessibility,
                and community engagement, we continue to push boundaries and redefine the way people interact with the
                world around them. Join us on our journey towards a brighter, more connected tomorrow with Pixie Mart.
            </p>
        </div>
    </div>;
}
