import React from 'react';
import ReactDOM from 'react-dom';
import reportWebVitals from './tests/reportWebVitals';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-circular-progressbar/dist/styles.css';

import './index.css';
import App from './main/pixiemart/components/app/App';

import { BrowserRouter } from "react-router-dom";
import { Provider } from 'react-redux';
import {store, persist_store} from './redux/store';
import { PersistGate } from 'redux-persist/integration/react';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {faEnvelope, faInfoCircle} from "@fortawesome/free-solid-svg-icons";

library.add(
    fab,
    faEnvelope,
    faInfoCircle
);

const app = (<React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persist_store}>
            <BrowserRouter>
                <App/>
            </BrowserRouter>
        </PersistGate>
    </Provider>
</React.StrictMode>);

const rootElement = document.getElementById("root");
// ReactDOM.render(app, rootElement);

if (rootElement !== null && rootElement.hasChildNodes()) {
    ReactDOM.hydrate(app, rootElement);
} else {
    ReactDOM.render(app, rootElement);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
