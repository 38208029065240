import {Link} from "react-router-dom";
import {CAR_POOL_HOME_URL} from "../CarPoolUrl";
import cp_android_qr from "../statics/images/cp_android_qr.webp";
import {CARPOOL_THEME_COLOR} from "../constants/Constatnt";

export function AboutPixieCarPool() {
    return <div className={"text-white d-flex"}>
        <Link to={CAR_POOL_HOME_URL} className={"no-text-decor d-flex shadow-sm rounded"} style={{background: CARPOOL_THEME_COLOR}}>
            <div className={"p-2 d-flex align-items-center"}>
                <img height={60} src={cp_android_qr} alt={"android_qr_code"} />
            </div>
            <div className={"text-white p-3 d-flex align-items-center"}>
                <div>
                    <p className={"fw-bold m-0"}>
                        Pixie Carpool at Airport & Stations
                    </p>
                    <p className={"m-0"}>
                        Click here to know more about Pixie Carpool
                    </p>
                </div>
            </div>
        </Link>
    </div>
}
