import cp_android_qr from "../statics/images/cp_android_qr.webp";
import cp_ios_qr from "../statics/images/cp_ios_qr.webp";
import cp_home_qr from "../statics/images/cp_home_qr.webp";
import {CARPOOL_ANDROID_APP_URL, CARPOOL_IOS_APP_URL} from "../constants/URLConstant";
import {APP_DOWNLOAD_URL, CAR_POOL_HOME_URL} from "../CarPoolUrl";
import {Link} from "react-router-dom";

export function AndroidAppDownload() {
    return <a rel={"noreferrer"} className={"no-text-decor"}
              href={CARPOOL_ANDROID_APP_URL} target={"_blank"} >
        <div className={"d-flex h-100"}>
            <div className={"d-flex shadow bg-white"}>
                <div className={"p-2"}>
                    <img height={160} src={cp_android_qr} alt={"android_qr_code"} />
                </div>
                <div className={"text-dark p-3 d-flex align-items-center"}>
                    <div>
                        <h4 className={"fw-bold"}>
                            Download for Android
                        </h4>
                        <p>
                            Click or Scan QR code to download the app in Android phone
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>
}

export function IOSAppDownload() {
    return <a rel={"noreferrer"} className={"no-text-decor"}
              href={CARPOOL_IOS_APP_URL} target={"_blank"} >
        <div className={"d-flex h-100"}>
            <div className={"d-flex shadow bg-white"}>
                <div className={"p-2"}>
                    <img height={160} src={cp_ios_qr} alt={"android_qr_code"} />
                </div>
                <div className={"text-dark p-3 d-flex align-items-center"}>
                    <div>
                        <h4 className={"fw-bold"}>
                            Download For iPhone
                        </h4>
                        <p>
                            Click or Scan QR code to download the app in iPhone
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </a>
}

export function PixieCarPoolAppDownload() {
    return <Link className={"no-text-decor"} to={CAR_POOL_HOME_URL + APP_DOWNLOAD_URL} >
        <div className={"d-flex my-5"}>
            <div className={"d-flex shadow bg-white"}>
                <div className={"p-2"}>
                    <img height={160} src={cp_home_qr} alt={"android_qr_code"} />
                </div>
                <div className={"text-dark p-3 d-flex align-items-center"}>
                    <div>
                        <h4 className={"fw-bold"}>
                            Download Pixie Carpool App
                        </h4>
                        <p>
                            Click or Scan QR code to download the app
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </Link>
}
