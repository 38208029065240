import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import {
    APP_DOWNLOAD_URL,
    CAR_POOL_DISCLAIMER_URL,
    CAR_POOL_FAQ_URL,
    CAR_POOL_POLICIES_BASE_URL,
    CAR_POOL_PRIVACY_POLICY_URL, FAQ_URLS
} from "./CarPoolUrl";
import {CarPoolHome} from "./home/CarPoolHome";
import CarPoolPrivacyPolicy from "./policies/CarPoolPrivacyPolicy";
import CarPoolDisclaimerPolicy from "./policies/CarPoolDisclaimerPolicy";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import {CarPoolFAQ} from "./faq/CarPoolFAQ";
import {DeleteAccountFAQ} from "./faq/questions/DeleteAccountFAQ";
import {CarpoolApplicationDownload} from "./application/CarpoolApplicationDownload";
import {SetCarPoolDocumentTitle} from "../../../managers/DocumentTitleManager";

class CarPoolUrlComponent extends Component {

    componentDidMount() {
        SetCarPoolDocumentTitle("Home", "Pixie Mart Carpool at Airport & Stations");
    }

    render() {
        return (
            <Routes>
                <Route path={HOME_URL} element={<CarPoolHome />}/>
                <Route path={APP_DOWNLOAD_URL} element={<CarpoolApplicationDownload />} />

                <Route path={CAR_POOL_POLICIES_BASE_URL} >
                    <Route path={CAR_POOL_PRIVACY_POLICY_URL} element={<CarPoolPrivacyPolicy />} />
                    <Route path={CAR_POOL_DISCLAIMER_URL} element={<CarPoolDisclaimerPolicy />} />
                </Route>
                <Route path={CAR_POOL_FAQ_URL} >
                    <Route path={CAR_POOL_FAQ_URL} element={<CarPoolFAQ />} />
                    <Route path={FAQ_URLS.DELETE_ACCOUNT_FAQ_URL} element={<DeleteAccountFAQ />} />
                </Route>
            </Routes>
        );
    }
}

export default CarPoolUrlComponent;
