import Footer from "../../../footer/Footer";
import {Policies} from "../../../footer/FooterInterface";
import {INSPIRE_DISCLAIMER_URL, INSPIRE_FAQ_URL, INSPIRE_HOME_URL, INSPIRE_PRIVACY_POLICY_URL} from "../InspireUrl";

export function InspireFooter({hidePolicies}: Readonly<{ hidePolicies?: boolean; }>) {
    return <section>
        <Footer contactUsName={"Pixie Inspire"}
                faqUrl={INSPIRE_HOME_URL + INSPIRE_FAQ_URL}
                policies={policies}
                hidePolicies={hidePolicies} />
    </section>
}

const policies: Policies[] = [
    {
        name: "Privacy Policy",
        link: INSPIRE_HOME_URL + INSPIRE_PRIVACY_POLICY_URL
    },
    {
        name: "Disclaimer",
        link: INSPIRE_HOME_URL + INSPIRE_DISCLAIMER_URL
    }
]
