import buddha from "../statics/buddha.jpg";
import {InspireFooter} from "../footer/InspireFooter";

export function InspireHome() {
    return <section>
        <div className={"py-5"}>
            <TitleContainer/>
            <SectionsContainer />
            <StoryContainer/>
            <div className={"container"}>
                <div className={"p-1 pt-4 fw-bold fst-italic"}>
                    Enlighten AI will be available to everyone very soon ...
                </div>
            </div>
        </div>
        <InspireFooter/>
    </section>
}

function TitleContainer() {
    return <div className={"container pb-4"}>
        <div className={"px-1"}>
            <h1 className={"fw-bold fs-1"}>
                Enlighten AI
            </h1>
            <h2 className={"fw-bold fs-4 text-secondary"}>
                Clarity, wisdom, inspiration, and motivation
            </h2>
            <p className={"fs-5"}>
                Meet EnlightenAI, your personal guide to understanding the true meaning of life. In our lives, we have
                good mentors and people who show us the right path, but sometimes we hesitate to share our problems and
                suffer internally. Enlighten AI steps in as a compassionate mentor, offering inspiration, motivation,
                and wisdom to help you find happiness and clarity, gently guiding you on the right path.
                <br/><br/>
                Whether you have questions about spirituality, enlightenment, or simply seek motivation and guidance,
                EnlightenAI is here to illuminate your path and uplift your spirit.
            </p>
        </div>
    </div>
}

function SectionsContainer() {
    return <div className={"container pb-4"}>
        <div className={"p-1"}>
            <h2 className={"fs-4 fw-bold"}>
                Enlighten AI capabilities
            </h2>
        </div>
        <div className={"pb-3 d-flex flex-wrap"}>
            <SectionItem title={"Spiritual Insights"} description={"Ask any question about spirituality, enlightenment, meditation, or mindfulness, and EnlightenAI will provide you with profound insights and wisdom to deepen your understanding."} />
            <SectionItem title={"Motivation"} description={"Get inspired and uplifted with daily motivation from the teachings of spiritual like Gautam Buddha"} />
            <SectionItem title={"Personalized Guidance"} description={"Receive personalized guidance tailored to your spiritual journey. Whether you're a beginner or an experienced practitioner, EnlightenAI will offer advice and practices to suit your needs."} />
        </div>
    </div>
}

function SectionItem({title, description}: {title: string, description: string}) {
    return <div className={"p-1 col-md-4"}>
        <div className={"p-2 rounded shadow-sm h-100 border border-1"} style={{backgroundColor: "rgb(244,231,208)"}}>
            <h3 className={"fs-5 fw-bold"}>
                {title}
            </h3>
            <p className={"small m-0"}>
                {description}
            </p>
        </div>
    </div>
}

function StoryContainer() {
    return <div className={"container"}>
        <div className={"p-1"}>
            <h2 className={"fs-4 fw-bold"}>
                What Enlighten AI says about suffering
            </h2>
        </div>

        <div className={"d-flex flex-wrap justify-content-center"}>
            <div className={"col-md-6 px-1"}>
                <img src={buddha} alt={"buddha"} className={"img-object-fit-cover rounded"}/>
            </div>
            <div className={"col-md-6 px-1"}>
                <p className={"fs-5"}>
                    Suffering is like a shadow that follows us everywhere. It can be big or small, but it's
                    something we all deal with.
                    Whether it's feeling sad, scared, or hurt, everyone goes through tough times.
                    But there's a way out of this cycle of suffering, and it's simpler than you might think.
                </p>
                <p className={"fs-5"}>
                    Suffering happens when we want things to be different from how they are.
                    Maybe we wish for more money, better health, or happier relationships.
                    When we don't get what we want, we feel upset.
                    It's like trying to hold onto a soap bubble, it always slips away.
                </p>
                <p className={"fs-5"}>
                    The key to escaping suffering is to change how we look at things.
                    Instead of focusing on what we don't have, we can appreciate what we do.
                    We can also learn to let go of things we can't control, like the past or other people's actions.
                    This doesn't mean ignoring problems but accepting them and finding peace in the present moment.
                </p>
            </div>
            <div className={"col-md-12 px-1"}>
                <p className={"fs-5"}>
                    There are easy things we can do every day to feel better.
                    Taking deep breaths, going for a walk in nature, or spending time with loved ones can all help.
                    Meditation, which is just sitting quietly and paying attention to our thoughts and feelings, can
                    also be powerful.
                    It helps us see that we're more than just our problems.
                </p>
                <p className={"fs-5"}>
                    One of the best ways to escape suffering is to focus on what's happening right now. Instead of
                    worrying about the future or dwelling on the past, we can enjoy the present moment. Whether it's
                    savoring a delicious meal or laughing with friends, being present brings peace and happiness.
                </p>
                <p className={"fs-5"}>
                    Suffering is a part of life, but it doesn't have to control us. By changing how we think and
                    being
                    present in the moment, we can break free from its grip. It's about appreciating what we have,
                    letting go of what we can't change, and finding joy in the simple things. So let's take a deep
                    breath, smile, and remember that happiness is always within reach.
                </p>
            </div>
        </div>
    </div>
}
