import {Link} from "react-router-dom";
import inspireLogo from "../statics/images/icons/inspire_icon.png";
import {INSPIRE_THEME_COLOR} from "../constants/Constatnt";
import {INSPIRE_HOME_URL} from "../InspireUrl";

export function AboutPixieEnlightenAI() {
    return <div className={"text-white d-flex"}>
        <Link to={INSPIRE_HOME_URL} className={"no-text-decor d-flex shadow-sm rounded"} style={{background: INSPIRE_THEME_COLOR}}>
            <div className={"p-2 d-flex align-items-center"}>
                <img height={60} src={inspireLogo} alt={"android_qr_code"} />
            </div>
            <div className={"text-white p-3 d-flex align-items-center"}>
                <div>
                    <p className={"fw-bold m-0"}>
                        Pixie Enlighten AI - Wisdom, Inspiration, and Motivation
                    </p>
                    <p className={"m-0"}>
                        Click here to know more about Enlighten AI
                    </p>
                </div>
            </div>
        </Link>
    </div>
}
