import {useEffect, useState} from "react";
import {AboutPixieMart} from "../../home/StaticWebHome";
import Footer from "../../../footer/Footer";

export function BrigadeHome() {
    const [counter, setCounter] = useState(10);
    const brigadeURL = "https://eldorado-bangalore.in";

    useEffect(() => {
        const interval = setInterval(() => {
            if (counter > 0) {
                setCounter(counter - 1);
            } else {
                window.location.href = brigadeURL;
            }
        }, 500);

        return () => {
            clearTimeout(interval);
        };
    }, [counter]);


    return <section className={"flex-fill d-flex flex-column"}>
        <AboutPixieMart/>
        <div className={"d-flex flex-column flex-fill"}>
            <div className={"py-5 flex-fill"}>
                <div className={"container"}>
                    <div className={"p-1 pt-4 fw-bold"}>
                        Redirecting to Brigade El Dorado ... <span className={"fw-normal"}>{counter}</span> <br/>
                        <a href={brigadeURL} rel={"noreferrer"} className={"no-text-decor"}>
                            {brigadeURL}
                        </a>
                    </div>
                </div>
            </div>
        </div>
        <Footer/>
    </section>;
}
