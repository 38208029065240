import {Route, Routes} from "react-router-dom";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import {BrigadeHome} from "./brigade/BrigadeHome";
import {REDIRECT_BRIGADE} from "./RedirectsUrl";
import React from "react";

export function RedirectUrlComponent() {
    return <Routes>
        <Route path={HOME_URL} element={<BrigadeHome/>} />
        <Route path={REDIRECT_BRIGADE} element={<BrigadeHome/>} />
    </Routes>
}
