import {Link} from "react-router-dom";
import twister_qr_code_home from "../statics/images/twister_qr_code_home.webp";
import {TWISTER_THEME_COLOR} from "../constants/Constatnt";
import {TWISTER_HOME} from "../TwisterUrl";

export function AboutPixieTongueTwister() {
    return <div className={"text-white d-flex"}>
        <Link to={TWISTER_HOME} className={"no-text-decor d-flex shadow-sm rounded"} style={{background: TWISTER_THEME_COLOR}}>
            <div className={"p-2 d-flex align-items-center"}>
                <img height={60} src={twister_qr_code_home} alt={"android_qr_code"} />
            </div>
            <div className={"text-white p-3 d-flex align-items-center"}>
                <div>
                    <p className={"fw-bold m-0"}>
                        Pixie Mart - Tongue Twister
                    </p>
                    <p className={"m-0"}>
                        Click here to know more about Tongue Twister
                    </p>
                </div>
            </div>
        </Link>
    </div>
}
