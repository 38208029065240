import React, {Component} from "react";
import {Route, Routes} from "react-router-dom";
import {HOME_URL} from "../../../constants/application/ApplicationUrl";
import {TwisterHome} from "./home/TwisterHome";
import {
    TWISTER_APP_DOWNLOAD_URL,
    TWISTER_DISCLAIMER_URL, TWISTER_FAQ_URL,
    TWISTER_POLICIES_BASE_URL,
    TWISTER_PRIVACY_POLICY_URL
} from "./TwisterUrl";
import {TwisterApplicationDownload} from "./application/TwisterApplicationDownload";
import TongueTwisterDisclaimerPolicy from "./policies/TongueTwisterDisclaimerPolicy";
import TongueTwisterPrivacyPolicy from "./policies/TongueTwisterPrivacyPolicy";
import {TongueTwisterFAQ} from "./faq/TongueTwisterFAQ";
import {SetTongueTwisterDocumentTitle} from "../../../managers/DocumentTitleManager";

class TwisterUrlComponent extends Component {
    componentDidMount() {
        SetTongueTwisterDocumentTitle("Home","Enhance your speech with Pixie Mart - Tongue Twister 🌟 Master the art of pronunciation through challenging twists and turns. Fun, engaging, and perfect for achieving fluency. Start your journey to speech perfection today.");
    }

    render() {
        return (
            <Routes>
                <Route path={HOME_URL} element={<TwisterHome />}/>
                <Route path={TWISTER_APP_DOWNLOAD_URL} element={<TwisterApplicationDownload />} />

                <Route path={TWISTER_POLICIES_BASE_URL} >
                    <Route path={TWISTER_PRIVACY_POLICY_URL} element={<TongueTwisterPrivacyPolicy />} />
                    <Route path={TWISTER_DISCLAIMER_URL} element={<TongueTwisterDisclaimerPolicy />} />
                </Route>

                <Route path={TWISTER_FAQ_URL} >
                    <Route path={TWISTER_FAQ_URL} element={<TongueTwisterFAQ />} />
                </Route>
            </Routes>
        );
    }
}

export default TwisterUrlComponent;
